import React, { useState, useEffect } from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';  //Added for pagination

import {  Link } from 'react-router-dom';
import {  DATE_FORMAT, FORMAT_TEXT } from "helpers/common";
import { DELETE_ALERT } from "helpers/constantsMessage"
import { APPLICATION, PAGE_LIMIT } from 'helpers/viewContent';
import { TextField, Dialog, DialogTitle, List, ListItem, ListItemText, Autocomplete } from '@mui/material';

//imported
import * as Path from 'routes/paths';
import * as actions from 'store/actions/index.js';
import {getApplicationList, removeApplication_} from 'store/services/applicationService';
import ReactPagination from "components/shared/ReactPagination";
import { getAnswersForApplication } from "store/actions/application";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';




const Listing = ({dispatch, itemsCountPerPage, totalItemsCount }) => {
  //pagination
//Adding new code for pagination
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);


  const [activePage, setActivePage] =  useState(parseInt(queryParams.get('page')) || 1);;
  const [listing, setListing] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [open, setOpen] = useState(false);
  const [searchValue_, setSearchValue_] = useState(-2);


  // Date filter states
  const [dateFrom, setDateFrom] = useState(null);  // State for "From" date
  const [dateTo, setDateTo] = useState(null);      // State for "To" date




  //currentPage = activePage -- added
  async function getData( currentPage = activePage  ) {
    try {
      let query = {
        page: activePage - 1,
        size: PAGE_LIMIT,
        key : searchValue,
        status : parseInt(searchValue_),

        dateFrom: dateFrom ? dateFrom.getTime() : null, // Converted to timestamp
        dateTo: dateTo ? dateTo.getTime() : null, 
        
      };


      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(getApplicationList(query));
      setListing(res?.data)
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }
  useEffect(() => {
    getData();
  }, [activePage, searchValue, searchValue_ , dateFrom, dateTo]  );

//Added
  useEffect(() => {
    const pageFromUrl = parseInt(queryParams.get('page') || 1 ) ;
    setActivePage(pageFromUrl);
    getData(pageFromUrl); // Fetch data based on the page from the URL
  }, [location.search]);
  

//pagination
const handlePageChange = (page) => {
  setActivePage(page);
  window.scrollTo(0, 0);
 // history.push(`?page=${page}`); // Update the URL with the current page number added
 history.push(`?page=${page}&status=${searchValue_}`); // Include status in URL
};


  const handleSearchChange = async (event) => {
    const inputValue = event.target.value;
    setSearchValue(inputValue);
  };

  const handleSearchChange__ = (event, value) => {
    setSearchValue_(value?.value ?? -2);
    setActivePage(1);  // Reset to first page
    history.push(`?page=1&status=${value?.value ?? -2}`);  };

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };
  
  
  

    //delete
    const deleteUsers_ = async (id) => {
      try {
        await DELETE_ALERT().then((willDelete) => {
          if (willDelete) {
            try {
              dispatch(removeApplication_(id)).then((res) => {
                getData();
              });
            } catch (err) {
              console.log(err);
            }
          }
        });
      } catch (err) {
        console.log(err);
      }
    };

    let filteredData = [
      { name:'Select status for filter', value:-2},
      { name:'Created', value:0 },
      { name:'Completed', value:1},
      { name:'Payment done', value:2},
      {name:'Document upload', value:3},
      {name:'Admin approval', value:4},
      {name:'Success', value:5},
      {name:'Fail', value:6},
      {name:'Edit access', value:7},
      {name:'Schedule Call', value:8},
      {name:'E-Sign Request', value:9},
      
  ]

    return(
        <>
<Helmet title={APPLICATION.CURRENT_MODULE} />
<div className="page-header">
    <h3 className="page-title">
        <span className="page-title-icon bg-gradient-primary text-white me-2">
            <i className="mdi mdi-chart-bar menu-icon"></i>
        </span>
        {APPLICATION.CURRENT_MODULE}
    </h3>
    <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
            <li className="breadcrumb-item">
                <Link to={Path.application}>{APPLICATION.CURRENT_MODULE}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">Listing</li>
        </ol>
    </nav>
</div>

<div className="row">
    <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
            <div className="card-body">
                <div className="row mb-4">
                    <div className="col-md-4">
                        <h4 className="card-title">Listing</h4>
                    </div>
                </div>
                <div className="row mb-4 align-items-end">
                    <div className="col-md-3">
                        {/* From Date Picker */}
                        <label htmlFor="dateFrom">From</label>
                        <DatePicker
                            selected={dateFrom}
                            onChange={(date) => setDateFrom(date)}
                            dateFormat="yyyy/MM/dd"
                            className="form-control"
                            id="dateFrom"
                        />
                    </div>
                    <div className="col-md-3">
                        {/* To Date Picker */}
                        <label htmlFor="dateTo">To</label>
                        <DatePicker
                            selected={dateTo}
                            onChange={(date) => setDateTo(date)}
                            dateFormat="yyyy/MM/dd"
                            className="form-control"
                            id="dateTo"
                        />
                    </div>
                    <div className="col-md-3">
                        {/* Search Input */}
                        <label htmlFor="search">Search</label>
                        <input
                            className="form-control"
                            autoComplete="new-password"
                            name="search"
                            type="text"
                            placeholder="Search"
                            pattern="[a-zA-Z0-9\s]+"
                            value={searchValue}
                            onChange={handleSearchChange}
                            id="search"
                        />
                    </div>
                    <div className="col-md-3">
                        {/* Filter by Name */}
                        <label htmlFor="filterByName">Filter by Name</label>
                        <Autocomplete
                            options={filteredData}
                            getOptionLabel={(option) => option.name}
                            value={filteredData.find((option) => option.value === searchValue_)}
                            onChange={handleSearchChange__}
                            renderInput={(params) => (
                                <TextField {...params} id="filterByName" variant="outlined" />
                            )}
                            onClick={handleDialogOpen}
                        />
                        <Dialog open={open} onClose={handleDialogClose}>
                            <DialogTitle>Select Status</DialogTitle>
                            <List>
                                {filteredData.map((item) => (
                                    <ListItem key={item.value} onClick={() => setSearchValue_(item.value)}>
                                        <ListItemText primary={item.name} />
                                    </ListItem>
                                ))}
                            </List>
                        </Dialog>
                    </div>
                </div>



                    <div class="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th> App Id </th>
                            <th> Linked App Id </th>
                            <th> Year </th>
                            <th> Applicant </th>
                            <th> Creator </th>
                            <th> Created Time</th>
                            <th> Updated Time</th>
                            <th> Assign User </th>
                            <th> Status </th>
        
                            <th> Action </th>
                          </tr>
                        </thead>
                        <tbody>
                          {listing.length > 0 && listing.map((value, i) => {
                            return(
                              <tr key={i}>
                                <td>{value?.id == 0 ? '---' : value?.id}</td>
                                <td className="py-1">
                                  {value?.linkedApplicationId == 0 ? '---' : value?.linkedApplicationId}
                                </td>
                                <td>{value?.fiscal ? value?.fiscal : '---'} </td>
                                <td>
                                {FORMAT_TEXT(value?.applicantName)}
                                </td>
                                <td> {value?.email ? FORMAT_TEXT(value?.email) : '---'} </td>
                                <td>{value?.timestampCreated ? DATE_FORMAT(value?.timestampCreated) : 'N/A'}</td>
                                <td>{value?.lastUpdatedTimestamp ? DATE_FORMAT(value?.lastUpdatedTimestamp) : 'N/A'}</td>
                               
                              
                                <td> {value?.adminName ? FORMAT_TEXT(value?.adminName) : '---'} </td>
                                
                                <td><label class="badge badge-success">{value.status == 0 ? 'Created' : value.status == 1 ? 'Completed' : value.status == 2 ? 'Payment done' : value.status == 3 ? 'Document upload' : value.status == 4 ? 'Admin approval' : value.status == 5 ? 'Success' : value.status == 6 ? 'Fail' : value.status == 7 ? 'Edit access'  :value.status == 8 ? 'Schedule Call' : value.status == 9 ?'E-Sign Request': 'N/A'}</label></td>
                                <td>
                                  <Link to={`${Path.applicationview}/${value.id}/${value.status}`} ><label class="badge badge-info" style={{cursor:'pointer'}} title="View">View</label></Link>&nbsp;&nbsp;
                                  <label class="badge badge-danger" style={{cursor:'pointer'}} title="Delete" onClick={() => deleteUsers_(value?.id)}>Delete</label>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                    {listing.length > 0 && (
                      <ReactPagination
                        activePage={activePage}
                        itemsCountPerPage={itemsCountPerPage}
                        totalItemsCount={totalItemsCount}
                        handlePageChange={handlePageChange}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
        </>

    )
}

const mapStateToProps = (state) => {
  return{
    itemsCountPerPage: state.Application.itemsCountPerPage,
    totalItemsCount: state.Application.totalItemsCount,
    loader: state.Application.loader,
  }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(Listing);